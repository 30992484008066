<template>
  <div class="EntrepriseCedee" >
    <div class="container-fluid">

      <macroStepper
        :step="2"
      />

      <h1 class="gavc-h1 text-center">2. Concernant l'entreprise </h1>
      <stepBar
        :labels="labels"
        :currentStep="step"
        :totalSteps="labels.length"
      />

      <div v-if="step === 1">
        <AspectsJuridique
          :id="audit.aspects_juridiques_activite_cedee_id"
          @next-step="step ++"
          @previous-step="$router.push(`/audit/situation-juridique/${audit.situation_juridique_id}`)"
        />
      </div>

      <div v-if="step === 2">
        <ElementsComptable
          :id="audit.elements_comptable_activite_cedee_id"
          @next-step="step ++"
          @previous-step="step --"
        />
      </div>

      <div v-if="step === 3">
        <ElementsFinanciers
          :id="audit.elements_financiers_activite_cedee_id"
          @next-step="step ++"
          @previous-step="step --"
        />
      </div>

      <div v-if="step === 4">
        <ElementsContractuels
          :id="audit.elements_contractuels_activite_cedee_id"
          @next-step="step ++"
          @previous-step="step --"
        />
      </div>

      <div v-if="step === 5">
        <ElementsFiscaux
          :id="audit.elements_fiscaux_activite_cedee_id"
          @next-step="step ++"
          @previous-step="step --"
        />
      </div>

      <div v-if="step === 6">
        <ElementsSociaux
          :id="audit.elements_sociaux_activite_cedee_id"
          @next-step="step ++"
          @previous-step="step --"
        />
      </div>

      <div v-if="step === 7">
        <Inventaire
          :id="audit.inventaire_activite_cedee_id"
          @next-step="$router.push(`/audit/paiement-prix/${audit.paiement_prix_id}`)"
          @previous-step="step --"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { APIConnected } from '@/api/APIConnected'

const AspectsJuridique = () => import('@/components/audit/entrepriseCedee/AspectsJuridique')
const ElementsComptable = () => import('@/components/audit/entrepriseCedee/ElementsComptable')
const ElementsFinanciers = () => import('@/components/audit/entrepriseCedee/ElementsFinanciers')
const ElementsContractuels = () => import('@/components/audit/entrepriseCedee/ElementsContractuels')
const ElementsFiscaux = () => import('@/components/audit/entrepriseCedee/ElementsFiscaux')
const ElementsSociaux = () => import('@/components/audit/entrepriseCedee/ElementsSociaux')
const Inventaire = () => import('@/components/audit/entrepriseCedee/Inventaire')

const macroStepper = () => import('@/components/audit/macroStepper')
const stepBar = () => import('@/components/layout/stepBar')

const apiConnectedService = new APIConnected()


export default {
  name: 'EntrepriseCedee',
  components: {
    AspectsJuridique,
    ElementsComptable,
    ElementsFinanciers,
    ElementsContractuels,
    ElementsFiscaux,
    ElementsSociaux,
    Inventaire,
    macroStepper,
    stepBar
  },
  data: function () {
    return {
      audit: {
        aspects_juridiques_activite_cedee_id: null
      },
      labels: [
        'Aspects juridiques',
        'Éléments comptables',
        'Éléments financiers​',
        'Engagements contractuels',
        'Aspects fiscaux',
        'Éléments sociaux',
        'Inventaire du stock'
      ],
      step: null
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  created: function() {
    this.getAudit()
  },
  methods: {
    getAudit () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAudit(this.token, this.$route.params.auditId)
      .then((result) => {
        this.audit = result.data
        this.step = result.data['entreprise_step_ongoing']
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('SituationJuridique getSituationJuridique API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>
